<template>
  <div class="checkHistory">
    <BannerTop
      :icon-left="['back']"
      :show-icon-right="false"
      class="checkHistoryBanner"
    >
      <template #title>
        <div class="courseSelect">
          <div
            v-for="(item, index) in courseSelectList"
            :key="item.course"
            class="tabItem flex-center"
            @click="courseSelectChange(index)"
            :class="{ selected: index === courseSelectIndex }"
          >
            {{ item.courseName }}
          </div>
        </div>
      </template>
      <template #right>
        <div class="grade flex-center" @click="selectGrade">
          <span> {{ grade.split("@")[0] }} </span>
          <van-icon name="arrow-down" class="icon" />
        </div>
      </template>
    </BannerTop>
    <div class="bookList">
      <div
        class="bookItem flex-row flex-al-center"
        v-for="item in showBookList"
        :key="item.bookId"
        @click="toCheckBookPage(item)"
      >
        <div class="bookCover">
          <img :src="ossUrl(item.book.thumbCoverPath)" />
        </div>
        <div class="bookInfo flex-col flex-jc-bt">
          <div class="bookName">{{ item.joinName }}</div>
          <div class="checkTime flex-row flex-al-center">
            <span>{{ item.latestUpdateTime }}</span>
            <img
              src="https://img01.yzcdn.cn/upload_files/2022/03/23/FvB4cKTCO94NyKUqvVwCRaM9ekii.png"
            />
          </div>
        </div>
      </div>
      <div v-if="showBookList.length === 0 && !loading" class="empty">
        <van-empty
          :image-size="[110, 108]"
          image="https://img01.yzcdn.cn/upload_files/2022/05/12/FlQF5I3ArNabZMqX1rq95JZ5fKEw.png"
          description="暂无记录"
        />
      </div>
    </div>
    <van-popup v-model:show="showGradePopup" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showGradePopup = false"
        @confirm="gradeConfirm"
      >
        <template #option="option">
          {{ option.split("@")[0] }}
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
/*
 * By Suxue
 * 2022/5/10
 */
import BannerTop from "@/components/BannerTop";
import { ref } from "vue";
import { dataList } from "@/project/smartHomeworkUserClient/views/wrongQuestionBook/helper";
import {
  initZidian,
  getJoinName,
} from "@/project/smartHomeworkUserClient/common/bookName";
import { listWrongData4Student } from "@/project/smartHomeworkUserClient/API/statistical";
import { uniq } from "lodash";
import { ossUrl } from "@/static/js/oss";
export default {
  name: "index",
  components: { BannerTop },
  setup() {
    const refDataList = ref([dataList.yuwen, dataList.shuxue, dataList.yingyu]);
    const grade = ref("");
    const showGradePopup = ref(false);
    const selectGrade = () => {
      showGradePopup.value = true;
    };
    const columns = ref([]);

    const gradeConfirm = (items) => {
      grade.value = items.join("");
      showGradePopup.value = false;
    };
    const setupRet = {
      dataList: refDataList,
      grade,
      selectGrade,
      showGradePopup,
      columns,
      gradeConfirm,
    };
    return setupRet;
  },
  data() {
    return {
      courseSelectList: [
        {
          course: 1,
          courseName: "数学",
        },
        {
          course: 2,
          courseName: "语文",
        },
        {
          course: 3,
          courseName: "英语",
        },
      ],
      courseSelectIndex: 0,
      bookList: [],
      studentGrade: "",
      groupBookObj: {},
      loading: true,
    };
  },
  computed: {
    selectCourse() {
      return this.courseSelectList[this.courseSelectIndex].course;
    },
    showBookList() {
      return (this.groupBookObj[this.grade] || []).filter(
        (i) => i.book.course === this.selectCourse
      );
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "smartHomeworkUserClient/user/getStudentUserInfo",
      true
    );
    // const StudentInfo =
    // this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
    this.loading = true;
    const toast = this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    try {
      await this.initData();
    } finally {
      this.loading = false;

      toast.clear();
    }
  },
  methods: {
    ossUrl,
    async initData() {
      await this.$store.dispatch(
        "smartHomeworkUserClient/user/getStudentUserInfo",
        true
      );
      // 请求数据
      const StudentInfo =
        this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
      if (StudentInfo && StudentInfo.gradeName) {
        const date0901 = new Date(new Date().getFullYear(), 9, 1);
        const volume = Date.now() < date0901.getTime() ? 1 : 2;
        this.StudentGrade =
          StudentInfo.gradeName +
          (volume === 1 ? "上" : "下") +
          `@${StudentInfo.grade}@${volume}`;
      }
      const zidian = await initZidian();
      const bookList = await listWrongData4Student(StudentInfo).then(
        (res) => res.data
      );
      // 按照maskbook年级册次分组
      const groupBookList = {};
      // 年级级联选择
      const grades = [];
      bookList.forEach((b) => {
        const { gradeName, volumeName, joinName } = getJoinName(b.book);
        const key =
          (gradeName + volumeName).replace("册", "") +
          "@" +
          b.book.grade +
          "@" +
          b.book.volume;
        groupBookList[key] = groupBookList[key] || [];
        groupBookList[key].push({
          ...b,
          joinName,
        });
        grades.push(gradeName);
      });
      this.groupBookObj = groupBookList;
      if (zidian) {
        const showGrades = Object.keys(groupBookList);
        this.columns = [
          {
            values: showGrades,
            defaultIndex:
              showGrades.findIndex((item) => item === this.StudentGrade) || 0,
          },
        ];
        this.grade =
          showGrades.find((item) => item === this.StudentGrade) ||
          showGrades[showGrades.length - 1] ||
          this.StudentGrade;
      }
    },
    courseSelectChange(index) {
      this.courseSelectIndex = index;
    },
    toCheckBookPage(item) {
      this.$router.push({
        path: "/smartHomeworkUserClient/checkHistory/checkBookPage",
        query: {
          bookId: item.bookId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.checkHistory {
  height: 100vh;
  background: linear-gradient(180deg, #ecf2f7 0%, rgba(233, 244, 254, 0) 100%);
  .checkHistoryBanner {
    height: 44px;
    .suxue-bannerTop_content {
      height: 44px;
      .suxue-bannerTop_title {
        padding-right: 40px;
      }
    }
  }
  .courseSelect {
    text-align: right;
    margin-right: 11px;
    .tabItem {
      position: relative;
      display: inline-block;
      width: 50px;
      text-align: center;
      font-size: 13px;
      color: #999999;

      &.selected {
        font-size: 20px;
        color: #3f90ff;

        &::after {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 3px;
          background: #3f90ff;
          border-radius: 3px;
        }
      }
    }
  }
  .grade {
    width: 90px;
    height: 27px;
    background: #f3f8ff;
    border-radius: 5px;
    font-size: 12px;
    color: #3f90ff;
    span {
      margin-right: 2px;
    }
  }
  .bookList {
    .bookItem {
      width: 347px;
      height: 111px;
      margin: 8px auto auto auto;
      background: #ffffff;
      border-radius: 6px;
      padding: 10px;
      .bookCover {
        img {
          width: 65px;
          height: 90px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
        }
      }
      .bookInfo {
        margin-left: 11px;
        height: 100%;
        .bookName {
          text-align: left;
          margin-top: 2px;
          font-size: 14px;
          line-height: 20px;
          color: #202020;
        }

        .checkTime {
          margin-bottom: 11px;
          font-size: 12px;
          color: #999999;

          img {
            margin-left: 4px;
            width: 51px;
          }
        }
      }
    }
  }
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    margin-left: auto;
    margin-right: auto;
    width: 347px;
    height: 330px;
    background: #ffffff;
    border-radius: 6px;
  }
}
</style>
